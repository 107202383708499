<template>
<div class="immoapp__container immoapp__container--gray immoapp__container--saved-search">

<h2 class="text-center">{{ $t("Was ist Ihre Immobilie wert?") }}</h2>

<v-stepper v-model="step" elevation="0" v-if="initialized">
  <v-stepper-header>
    <v-stepper-step :complete="step > 1" step="1" editable color="primary"></v-stepper-step>
    <v-divider></v-divider>

    <v-stepper-step :complete="step > 2" step="2" :editable="hasCategory" color="primary"></v-stepper-step>
    <v-divider></v-divider>

    <v-stepper-step :complete="step > 3" step="3" :editable="hasCategory" color="primary"></v-stepper-step>
    <v-divider v-if="noContactForm == undefined"></v-divider>

    <v-stepper-step step="4" :complete="sent" v-if="noContactForm == undefined" :editable="hasCategory" color="primary"></v-stepper-step>
  </v-stepper-header>

  <v-stepper-items>
    <v-stepper-content step="1">
      <div class="immoapp__step__content">
        <h3>{{ $t("Welche Immobilie möchten Sie bewerten?") }}</h3>
        <inquiry-categories
          :category="category" @category="setCategory"
          :marketingType="marketingType" @marketingType="setMarketingType"
          :objectType="objectType" @objectType="setObjectType"
          :categoryList="config.categoryList"
          inquiryType="lead"
        ></inquiry-categories>
      </div>

      <div class="immoapp__step__footer">
        <div class="row">
          <div class="col-12 text-left">
          </div>
          <div class="col-12 text-right">
            <button v-if="category" class="btn btn-link next" @click="step = 2">{{ $t("weiter") }}</button>
          </div>
        </div>
      </div>
    </v-stepper-content>

    <v-stepper-content step="2">
      <div class="immoapp__step__content">
        <inquiry-address v-if="step == 2"
          inquiryType="lead"
          :autocompleteText="$t('Bitte geben Sie PLZ / Ort ein')"
          :location="location"
          @update="setLocationFields"></inquiry-address>
      </div>

      <div class="immoapp__step__footer">
        <div class="row">
          <div class="col-12 text-left">
            <button class="btn btn-link prev" @click="step = 1">{{ $t("zurück") }}</button>
          </div>
          <div class="col-12 text-right">
            <button class="btn btn-link next" @click="step = 3">{{ $t("weiter") }}</button>
          </div>
        </div>
      </div>
    </v-stepper-content>

    <v-stepper-content step="3">
      <div class="immoapp__step__content">
        <inquiry-fields :fields="selectedFields" :marketing-type="marketingType" @fieldChanged="changeField"></inquiry-fields>
      </div>

      <div class="immoapp__step__footer">
        <div class="row">
          <div class="col-12 text-left">
            <button class="btn btn-link prev" @click="step = 2">{{ $t("zurück") }}</button>
          </div>
          <div class="col-12 text-right">
            <button class="btn btn-link next" v-if="noContactForm == undefined" @click="step = 4">{{ $t("weiter") }}</button>
          </div>
        </div>
      </div>
    </v-stepper-content>

    <v-stepper-content step="4" v-if="noContactForm == undefined">
      <div class="immoapp__step__content">
        <contact-form :requiredFields='["email"]'
          submitUrl="/wp-json/sw-immoapp/v1/contact/lead"
          :prop-inquiry="inquiry"
          :authentication-token="authenticationToken"
          :button-text="$t('Formular abschicken')"
          @sent="onSent()"
        >
          <contact-logged-in></contact-logged-in>
          <contact-fields v-if="loggedIn" :outline="false" :exclude="['company', 'contactType', 'message', 'person', 'phone_email']"></contact-fields>
          <contact-fields v-else :outline="false" :exclude="['company', 'contactType', 'message']"></contact-fields>
        </contact-form>
      </div>


      <div class="immoapp__step__footer">
        <div class="row">
          <div class="col-12 text-left">
            <button class="btn btn-link prev" v-if="!sent" @click="step = 3">{{ $t("zurück") }}</button>
          </div>
          <div class="col-12 text-right">
          </div>
        </div>
      </div>
    </v-stepper-content>
  </v-stepper-items>

</v-stepper>
<slot v-if="initialized"></slot>
</div>
</template>

<script>

import InquiryCategories from "./InquiryCategories";
import InquiryAddress from "./InquiryAddress";
import InquiryFields from "./InquiryFields";
//import InquiryField from "./InquiryField";
import ContactForm from "../../contact/ContactForm";
import ContactFields from "../../contact/ContactFields";
import ContactLoggedIn from "./ContactLoggedIn";
import config from "./ConfigLead";
import inquiryFieldsConfig from "../utils/InquiryFieldsConfig";

export default {
  name: "LeadWizard",

  components: {
    InquiryCategories,
    InquiryAddress,
    InquiryFields,
    ContactForm,
    ContactFields,
    ContactLoggedIn
  },

  props: [
    "noContactForm"
  ],

  data() {
    return {
      initialized: false,
      sent: false,
      marketingType: "BUY",
      objectType: "LIVING",
      category: null,
      step: 1,
      location: null,
      fields: [],
      config
    }
  },

  computed: {
    authenticationToken() {
      return this.$store.state.auth.authenticationToken;
    },

    loggedIn() {
      return this.$store.state.user.loggedIn;
    },

    inquiry() {
      const inquiry = {};

      if (this.marketingType) {
        inquiry.marketingType = this.marketingType;
      }

      if (this.objectType) {
        inquiry.objectType = this.objectType;
      }

      if (this.category) {
        inquiry.categoryId = this.category.id;
      }

      if (this.location) {
        inquiry.locations = [this.location];
      }

      inquiry.fields = [];
      if (this.priceField) {
        inquiry.fields.push(this.priceField.ds);
      }
      this.detailFields.forEach(f => {
        inquiry.fields.push(f.ds);
      });

      return inquiry;
    },

    hasCategory() {
      return !(this.category == null);
    },

    priceField() {
      return this.fields.find(f => {
        return f.id == "price.value";
      });
    },

    detailFields() {
      return this.fields.filter(f => {
        return f.id != "price.value";
      });
    },

    selectedFields() {
      if (this.category) {
        let fieldNames = [];
        config.categoryList.forEach(c => {
          if (c.id == this.category.id) {
            c.fieldList.forEach(f => {
              if (typeof f == "object") {
                fieldNames.push(f.var);
              } else {
                fieldNames.push(f);
              }
            });
          }
        });

        return this.detailFields.filter(f => {
          return fieldNames.indexOf(f.id) > -1;
        });
      }

      return this.detailFields;
    }
  },

  created() {
    this.$store.dispatch("loading", true);
    inquiryFieldsConfig(config, t => {
        return this.$t(t);
      }).then(fieldsConfig => {
        this.fields = fieldsConfig;

        this.category = this.config.categoryList[0];

        this.initialized = true;
        this.$store.dispatch("loading", false);
      });
  },

  methods: {
    changeField(field, trigger) {
      //const fields = [];
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].id == field.id) {
          this.fields[i].ds = field.ds;
          this.$set(this.fields, i, Object.assign({}, this.fields[i]));
        }
      }

      if (trigger == undefined || trigger) {
        this.triggerChange();
      }
    },

    triggerChange() {
      this.$emit("change", this.inquiry);
    },

    setMarketingType(val) {
      this.marketingType = val;
      this.triggerChange();
    },

    setObjectType(val) {
      this.objectType = val;
      this.category = null;
      this.triggerChange();
    },

    setCategory(val) {
      this.category = val;
      this.triggerChange();
    },

    setLocationFields(list) {
      let l = {};
      if (this.location) {
        l = this.location;
      }
      Object.keys(list).forEach(k => {
        l[k] = list[k];
      });

      this.location = l;

      this.triggerChange();
    },

    onSent() {
      this.sent = true;
      this.$emit("sent");
    }
  }
};
</script>
