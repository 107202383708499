<template>
<v-app class="mt-4 mb-3 mb-lg-10">
  <v-container fluid>
    <h1>
      Ratgeber
    </h1>

    <p class="heading-small">
      Fordern Sie jetzt unseren Ratgeber an.
    </p>

    <v-row>
      <v-col cols="24" md="12">
      <contact-form :name="name" :required-fields="requiredFields">
          <contact-fields
            :exclude="['message', 'company', 'contactReason', 'contactType']"
            :include="['contactRatgeber']"></contact-fields>
      </contact-form>
      </v-col>

      <v-col cols="24" md="11" offset-md="1">
        <contact-company-info></contact-company-info>
      </v-col>
    </v-row>
  </v-container>
</v-app>
</template>

<script>
import ContactForm from "./ContactForm";
import ContactFields from "./ContactFields";
import ContactCompanyInfo from "./ContactCompanyInfo";

export default {
  name: "ContactFormApp",

  components: {
    ContactForm,
    ContactFields,
    ContactCompanyInfo
  },

  data() {
    return {
      name: "ratgeber"
    }
  },

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    },

    exclude() {
      if (this.$root.exclude) {
        return this.$root.exclude;
      }

      return [];
    },

    include() {
      if (this.$root.include) {
        return this.$root.include;
      }

      return [];
    }
  }
};
</script>
