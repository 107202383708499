<template>
<div>
  <div class="contact__info">
    <div class="first-col">
      <span class="fas fa-envelope"></span>
    </div>

    <div>
      <div>
      <h3>{{ company.name }}</h3>

      <p class="text-small">
        Der grüne Makler und Verwalter.
      </p>

      <ul class="text-small">
        <li>
          <span>Adresse:</span>
          {{ company.street }}, {{ company.zip }} {{ company.city }}
        </li>

        <li>
          <span>Telefon:</span>
          <a :href="'tel:' + company.phone">
              {{ company.phone }}
          </a>
        </li>

        <li>
          <span>E-Mail:</span>
          <a :href="'mailto:' + company.email">
            E-Mail senden <i class="fas fa-angle-right"></i></a>
        </li>
      </ul>
      </div>
    </div>
  </div>

  <!-- <div class="contact__info">
    <div class="first-col">
      <span class="fas fa-map-marker-alt"></span>
    </div>

    <div>
      <div>
        <h3>Ihr Weg zu uns</h3>

        <p class="text-small">
          Nehmen Sie mit uns Kontakt auf oder besuchen Sie uns direkt persönlich in unserem Ladenlokal. Wir freuen uns auf Sie.
        </p>

        <a :href="'https://www.google.de/maps?daddr=' + company.street + ', ' + company.zip + '+' + company.city + '&saddr='"
          title="Route jetzt berechnen"
          target="_blank"
          class="mb-1 d-block">
            Route jetzt berechnen <i class="fas fa-angle-right"></i>
        </a>
      </div>
    </div>
  </div> -->
</div>
</template>

<script>
export default {
  name: "ContactCompanyInfo",

  computed: {
    company() {
      return this.$yeti.company();
    }
  },

  methods: {
    onMapLoad(o) {
      o.L.marker(o.defaultCenter).addTo(o.map);
      this.mapCenter = o.defaultCenter;
    }
  }
}
</script>
