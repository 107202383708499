<template>
<div ref="cookieNotice" :class="{ 'cookie-notice__relative': fixed }">
<transition name="fade">
  <div class="cookie-notice" v-if="show">
  <div class="ast-container">
    <explorer-warning></explorer-warning>
    <a @click.prevent="decline" class="cookie-notice-close"><i class="far fa-times"></i></a>
    <p>
      Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können,
      verwenden wir Cookies.
    </p>
    <p>Folgende Tools werden eingesetzt:</p>
    <ul v-if="tools">
      <li v-for="t in tools" :key="t">{{ t }}</li>
    </ul>
    <p>
    <cookie-notice-link class="btn btn-block btn-primary">Ich akzeptiere</cookie-notice-link>
    </p>
    <p><strong>Weitere Informationen zu Cookies erhalten Sie in unserer
      <a :href="this.$yeti.privacyUrl()" target="_blank">Datenschutzerklärung</a>.</strong>
    </p>
  </div>
  </div>
</transition>
</div>
</template>

<script>
import deps from "../../depsloader";
import ExplorerWarning from "./ExplorerWarning";
import CookieNoticeLink from "./CookieNoticeLink";
import tracking from "./tracking";
import "./CookieNotice.scss";

let fixedTimeout;
let footerElm = null;
let cookieNoticeElm = null;

window.gaOptout = function() {
  deps.load("js-cookie").then((Cookies) => {
    Cookies.set("cookienotice", "declined", {expires: 3650});
    alert("Die Funktion wurde deaktiviert.");
  });
};

export default {
  name: "CookieNotice",

  components: {
    ExplorerWarning,
    CookieNoticeLink
  },

  data() {
    return {
      loaded: false,
      show: false,
      fixed: false,
      tools: []
    }
  },

  mounted() {
    const cookieNotice = this.$yeti.cookieNotice();

    if (!cookieNotice.enabled) {
      return;
    }

    this.tools = cookieNotice.tools;

    deps.load("js-cookie").then((Cookies) => {
      const notice = Cookies.get("cookienotice");
      if (notice == "accepted") {
        this.hide();
        this.track();
      } else if (notice == "declined") {
        this.hide();
      } else {
        this.load();
      }
    });
  },

  methods: {
    load() {
      if (this.loaded) {
        this.show = true;
        return;
      }

      this.loaded = true;
      this.show = true;

      this.observer = new IntersectionObserver(changes => {
          this.triggerChanges(changes);
      }, {});

      footerElm = document.querySelector("footer");
      cookieNoticeElm = this.$refs.cookieNotice;

      this.observer.observe(footerElm);
      this.observer.observe(cookieNoticeElm);
    },

    accept() {
      deps.load("js-cookie").then((Cookies) => {
        Cookies.set("cookienotice", "accepted", {expires: 30});
        this.hide();
        this.track();
      });
    },

    decline() {
      deps.load("js-cookie").then((Cookies) => {
        Cookies.set("cookienotice", "declined", {expires: 30});
        this.hide();
      });
    },

    triggerChanges(changes) {
      const change = changes[0];

      if (change.target == footerElm && !this.fixed) {
        if (change.intersectionRatio > 0.0) {
          this.fixed = true;
        }
      } else if (change.target == cookieNoticeElm && this.fixed) {
        if (change.intersectionRatio == 0) {
          this.show = false;
          if (fixedTimeout) {
            clearTimeout(fixedTimeout);
          }
          fixedTimeout = setTimeout(() => {
            this.fixed = false;
            this.show = true;
          }, 500);
        }
      }
    },

    track() {
      if (navigator.userAgent.indexOf("Page Speed Insights") > -1) {
        return;
      }

      const googleAnalytics = this.$yeti.googleAnalytics();
      if (googleAnalytics) {
        tracking.googleAnalytics(googleAnalytics.userId);
      }

      const googleAds = this.$yeti.googleAds();
      if (googleAds) {
        tracking.googleAnalytics(googleAds.gtagId);
      }
    },

    hide() {
      this.show = false;

      const elm = document.querySelector("#resetCookieNotice");
      if (elm) {
        elm.style.display = "";
        elm.onclick = () => {
          this.load();
          deps.load("js-cookie").then((Cookies) => {
            Cookies.remove("cookienotice");
          });
          elm.style.display = "none";
        };
      }
    }
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
