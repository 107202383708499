<template>
<div>
  <div class="row" v-if="exclude.indexOf('company') == -1">
    <div class="col-sm-24">
      <contact-input name="company" label="Firma" group="contact"></contact-input>
    </div>
  </div>

  <div class="row" v-if="exclude.indexOf('person') == -1">
    <div class="col-sm-8">
      <contact-select name="salutation" label="Anrede" group="contact" values="Frau,Herr" placeholder="Anrede"></contact-select>
    </div>
    <div class="col-sm-8">
      <contact-input name="firstname" label="Vorname" group="contact"></contact-input>
    </div>
    <div class="col-sm-8">
      <contact-input name="lastname" label="Nachname" group="contact"></contact-input>
    </div>
  </div>

  <div class="row" v-if="exclude.indexOf('phone_email') == -1">
    <div class="col-sm-12">
      <contact-input name="phone" label="Telefon" group="contact"></contact-input>
    </div>
    <div class="col-sm-12">
      <contact-input name="email" label="E-Mail" group="contact"></contact-input>
    </div>
  </div>

  <div class="row" v-if="fields.indexOf('contactReason') > -1">
    <div class="col-sm-24">
      <contact-select
        name="contactReason"
        label="Ihr Wunsch"
        group="contact"
        values="Besichtigung|besichtigung, Anruf|anruf, Weitere Details|detail"
      ></contact-select>
    </div>
  </div>

  <div class="row" v-if="fields.indexOf('contactRatgeber') > -1">
    <div class="col-sm-24">
      <contact-select
        name="contactRatgeber"
        label="Wunsch Ratgeber"
        group="contact"
        values="Erbimmobilie,Scheidungsimmobilie"
      ></contact-select>
    </div>
  </div>

  <div class="row" v-if="exclude.indexOf('message') == -1">
    <div class="col-sm-24">
      <contact-input  textarea label="Nachricht" name="message" group="contact"></contact-input>
    </div>
  </div>

  <div class="row" v-if="exclude.indexOf('contactType') == -1">
    <div class="col-sm-24">
      <v-radio-group v-model="contactType" row label="Kontaktaufnahme per: " class="my-0 py-0" :hide-details="true">
        <v-radio hide-details label="E-Mail" value="E-Mail" off-icon="fal fa-circle" on-icon="far fa-dot-circle"></v-radio>
        <v-radio hide-details label="Telefon" value="Telefon" off-icon="fal fa-circle" on-icon="far fa-dot-circle"></v-radio>
      </v-radio-group>
    </div>
  </div>
</div>
</template>

<script>
import ContactInput from "./ContactInput.vue";
import ContactSelect from "./ContactSelect.vue";

export default {
  name: "ContactFields",

  components: {
    ContactInput,
    ContactSelect
  },

  props: {
    include: {
      type: Array,
      default: () => []
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      contactType: []
    };
  },

  computed: {
    fields() {
      if (!this.include) {
        return [];
      }

      return [...this.include];
    }
  },

  mounted() {
    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    mapValue() {
      this.$root.contactForm.contact.contactType = this.contactType;
    }
  }
};
</script>
